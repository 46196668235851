import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


const Home = (props) => {
const [currentSlide, setCurrentSlide] = useState(0);
const [isAnimating, setIsAnimating] = useState(false);
const [isLoading, setIsLoading] = useState(true);


useEffect(() => {
  const iframe = document.querySelector('iframe');
  iframe.onload = () => setIsLoading(false);
}, []);

    let settings = {
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      dots: false,
      autoplaySpeed: 5000,   
    };
  

  let clipPathValue ="clip-path: polygon(0 100%, 0 0, 57% 0, 56% 53%, 43% 61%, 43% 82%, 47% 88%, 47% 100%); clip-path: polygon(0 100%, 0 0, 57% 0, 56% 53%, 43% 61%, 43% 82%, 47% 88%, 47% 100%);";
  return (
    <>
    {/*<Container>   */}
    <Carousel {...settings}
      beforeChange={(oldIndex, newIndex) => {setCurrentSlide(newIndex);
        setIsAnimating(true);}}
      afterChange={(index) => {
        setIsAnimating(false);
        setCurrentSlide(index);}}
    >
      
      <SliderContainer index={0} currentSlide={currentSlide} bgImage={"/images/4kCapLogo2.png"} xOffset={"400px 50%"} bgScale={"cover"} clipPath={clipPathValue} isAnimating={isAnimating}>   
        <DividerPanel clipPath={clipPathValue} index={0} currentSlide={currentSlide} isAnimating={isAnimating}></DividerPanel>
         <iframe src="https://player.vimeo.com/video/1041220855?autoplay=1&amp;playsinline=1&amp;color=CFD61E&amp;controls=0&amp;autopause=0&amp;loop=1&amp;muted=1&amp;title=0&amp;portrait=1&amp;byline=1#t="></iframe>                 
          <ProjectTitle index={0} currentSlide={currentSlide} isAnimating={isAnimating}>Flipper Frenzy</ProjectTitle>
          <CarouselSubTitle index={0} currentSlide={currentSlide} isAnimating={isAnimating}>Racing, but with seals</CarouselSubTitle> 
         <a href = "/flipperfrenzy"> <CheckOut>Check Out</CheckOut></a>
         </SliderContainer>

        <SliderContainer index={1} currentSlide={currentSlide} bgImage={"/images/Daydreamer2Splash3.png"} xOffset={"400px 50%"} bgScale={"cover"} clipPath={clipPathValue} isAnimating={isAnimating}>   
        <DividerPanel clipPath={clipPathValue} index={1} currentSlide={currentSlide} isAnimating={isAnimating}></DividerPanel>
         <iframe src="https://player.vimeo.com/video/791485904?autoplay=1&amp;playsinline=1&amp;color=CFD61E&amp;controls=0&amp;autopause=0&amp;loop=1&amp;muted=1&amp;title=0&amp;portrait=1&amp;byline=1#t="></iframe>                 
          <ProjectTitle index={1} currentSlide={currentSlide} isAnimating={isAnimating}>Daydreamer 2</ProjectTitle>
          <CarouselSubTitle index={1} currentSlide={currentSlide} isAnimating={isAnimating}>Hack n Slash Scmup</CarouselSubTitle> 
         <a href = "/daydreamer2"> <CheckOut>Check Out</CheckOut></a>
         </SliderContainer>
    
          <SliderContainer index={2} currentSlide={currentSlide} bgImage={"/images/SlogsSplash.png"} xOffset={"450px 50%"} bgScale={"cover"} clipPath={clipPathValue} isAnimating={isAnimating}>   
          <DividerPanel clipPath={clipPathValue} index={2} currentSlide={currentSlide} isAnimating={isAnimating}></DividerPanel>
         <iframe src="https://player.vimeo.com/video/791487632?autoplay=1&amp;playsinline=1&amp;color=CFD61E&amp;controls=0&amp;autopause=0&amp;loop=1&amp;muted=1&amp;title=0&amp;portrait=1&amp;byline=1#t=" ></iframe>        
          <ProjectTitle index={2} currentSlide={currentSlide} isAnimating={isAnimating}>Slogs Metaverse</ProjectTitle>
          <CarouselSubTitle index={2} currentSlide={currentSlide} isAnimating={isAnimating}>NFT Action Rpg</CarouselSubTitle> 
          <a href = "/slogs"> <CheckOut>Check Out</CheckOut></a>
          </SliderContainer>
    
          <SliderContainer index={3} currentSlide={currentSlide} bgImage={"/images/DaydreamerSplash2.png"} xOffset={"300px 50%"}bgScale={"cover"} clipPath={clipPathValue} isAnimating={isAnimating}>  
          <DividerPanel clipPath={clipPathValue} index={3} currentSlide={currentSlide} isAnimating={isAnimating}></DividerPanel>
         <iframe src="https://player.vimeo.com/video/791486119?autoplay=1&amp;playsinline=1&amp;color=CFD61E&amp;controls=0&amp;autopause=0&amp;loop=1&amp;muted=1&amp;title=0&amp;portrait=1&amp;byline=1#t="></iframe>        
          <ProjectTitle index={3} currentSlide={currentSlide} isAnimating={isAnimating}>Daydreamer</ProjectTitle>
          <CarouselSubTitle index={3} currentSlide={currentSlide} isAnimating={isAnimating}>Side Scrolling Shooter</CarouselSubTitle> 
          <a href = "/daydreamer"> <CheckOut>Check Out</CheckOut></a>
          </SliderContainer>
    
          <SliderContainer index={4} currentSlide={currentSlide} bgImage={"/images/DemonBlasterSplash3.png"} xOffset={"100px 50%"} bgScale={"cover"} clipPath={clipPathValue} isAnimating={isAnimating}>   
          <DividerPanel clipPath={clipPathValue} index={4} currentSlide={currentSlide} isAnimating={isAnimating}></DividerPanel>
         <iframe src="https://player.vimeo.com/video/791478206?autoplay=1&amp;playsinline=1&amp;color=CFD61E&amp;controls=0&amp;autopause=0&amp;loop=1&amp;muted=1&amp;title=0&amp;portrait=1&amp;byline=1#t=" ></iframe>        
          <ProjectTitle index={4} currentSlide={currentSlide} isAnimating={isAnimating}>Demon Blaster</ProjectTitle>
          <CarouselSubTitle index={4} currentSlide={currentSlide} isAnimating={isAnimating}>VR MOBA</CarouselSubTitle> 
          <a href = "/demonblaster"> <CheckOut>Check Out</CheckOut></a>
          </SliderContainer>
    
    </Carousel>
    {/*</Container>*/}
    </>
  );
};


const CheckOut = styled.div`
position: absolute;

bottom: 70px;
color: rgba(255,255,255,.5);
font-size: 32px;
font-weight: bold;
text-align: center;
padding:12px 16px;
background-color: rgb(75, 75, 75,.5);
text-shadow: 2px 2px 4px black;
box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
rgb(0 0 0 / 73%) 0px 16px 10px -10px;
border-radius:5px;
border: solid 0px rgba(111,111,111,.4);
    
@media (min-width:800px)
{
  left:150px;
}

@media (max-width:800px)
{
  font-size: 28px;
  bottom: 20vh;
  left:50vw;
  //right:40px;
}
  transition: transform 0.1s ease-in-out;
  :hover {
    transform: scale(1.03);
    background-color: rgba(255,255,255,.75);
    color: rgba(25,25,25,1);
    border: solid 0px rgba(255,255,255,.5);
    text-shadow: none;
  } 
`;

const DividerPanel = styled.div`
clip-path: ${props => props.clipPath};
background-color: rgb(0, 0,0,.7);
box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
z-index:3;
width:1200px;
height: 100%;
left:0;
top:0;
backdrop-filter: blur(64px);
z-index: ${props => (props.isAnimating  ? -5: 5)};
${({index, currentSlide}) => 
index === currentSlide ? 
  css`
    animation: slideInFromLeftPanel .3s  ease-out forwards;
  ` : 
  css`
    animation: slideOutFromLeftPanel -s ease-out forwards;
  `
}


@keyframes slideInFromLeftPanel {
  from {

   transform: translateX(-300px);
   opacity:.5;
  }
  to {
    opacity:1;
    transform: translateX(0px);
  }
}

@keyframes slideOutFromLeftPanel {
  from {
    opacity:1;
    transform: translateX(0px);
  }
  to {
   opacity:.5;
    transform: translateX(-300px);
  }
}
@media (max-width:800px)
{
  width:400px;
}
`;


const ProjectTitle = styled.div`
  position: absolute;
  left:200px;
  top: 630px;
  color: white;
  font-size: 50px;
  font-weight: bold;
  text-align: left;
  padding:5px 32px;
  background-color: rgb(65, 65, 65,1);
  text-shadow: 2px 2px 4px black;
  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
  rgb(0 0 0 / 73%) 0px 16px 10px -10px;
  border-radius:8px;
  opacity:0;
  
  @media (max-width:800px)
  {
    font-size: 32px;
    top: 255px;
  }

  ${({index, currentSlide}) => 
  index === currentSlide ? 
    css`
      animation: slideInFromLeftTitle .4s .75s ease-out forwards;
    ` : 
    css`
      animation: slideOutFromLeftTitle -s ease-out forwards;
    `
  }


  @keyframes slideInFromLeftTitle {
    from {
      opacity: 0;
      left: -500px;
    }
    to {
      opacity: 1;
      left: 130px;
    }
  }
  
  @keyframes slideOutFromLeftTitle {
    from {
      opacity: 1;
      left: 130px;
    }
    to {
      opacity: 0;
      left: -500px;
    }
  }
`;

const CarouselSubTitle = styled.div`
  position: absolute;
  left:170px;
  top:710px;
  color: white;
  font-size: 25px;
  font-weight:100;
  text-align: left;
  padding:5px 16px;
  background-color: rgb(25, 25, 25);
  text-shadow: 2px 2px 4px #333;
  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
  rgb(0 0 0 / 73%) 0px 16px 10px -10px;

  opacity:0;
  z-index: ${props => (props.isAnimating  ? -8 : 6)};
  @media (max-width:800px)
  {
    font-size: 20px;
    top: 310px;
  }

  ${({index, currentSlide}) => 
  index === currentSlide ? 
    css`
      animation: slideInFromLeftSubTitle .4s 1s ease-out forwards;
    ` : 
    css`
      animation: slideOutFromLeftSubTitle -s ease-out forwards;
    `
  }


  @keyframes slideInFromLeftSubTitle {
    from {
      opacity: 0;
      left: -500px;
    }
    to {
      opacity: 1;
      left: 130px;
    }
  }
  
  @keyframes slideOutFromLeftSubTitle {
    from {
      opacity: 0;
      left: 125px;
      left: -15%;
    }
    to {
      opacity: 0;
      left: -500px;
    }
  }
`;

const Carousel = styled(Slider)`
 margin-left:-100px;

 & > button {
  opacity: .5;
  height: 200%;
  width: 5vw;
  

  &:hover {
    opacity: 1;
    transition: opacity 0.2s   ease 0s;
  }
}

ul li button {
  &:before {
    font-size: 10px;
    color: rgb(150, 158, 171);
  }
}

li.slick-active button:before {
  color: white;
}

.slick-list {
  overflow: initial;
}

.slick-prev {
  left: 25px;
}

.slick-next {
  right: 25px;
  top:50vh;
}

`;




const SliderContainer = styled.div`

  position:fixed;
  background-image: url(${props => props.bgImage});
  background-repeat: no-repeat;
  background-position:0 0;
  width: 100vw;
  height: 100vh;
  background-position: ${props => props.xOffset};
 


  @media (min-aspect-ratio: 5/3) {
    /* If width is greater than height */ 
      background-size: 100vw auto;

  }
  
  @media (max-aspect-ratio: 5/3) {
    /* If height is greater than width */
      background-size: auto 100vh; 
      background-position:0 0;
  }

  @media (max-width:800px)
  {
    background-size: 1500px auto; 
    background-position:-500px 0;
  }

overflow:hidden;  
  
  @keyframes slideInFromLeft {
    from {
      opacity: 0;
      left: -500px;
    }
    to {
      opacity: 1;
      left: 130px;
    }
  }
  
  @keyframes slideOutFromLeft {
    from {
      opacity: 1;
      left: 130px;
    }
    to {
      opacity: 0;
      left: -500px;
    }
  }

  iframe {
    background-image: url('/images/loading1.gif');
    background-size: cover;
    background-position: center;

    ${({index, currentSlide}) => 
      index === currentSlide ? 
        css`
          animation: slideInFromLeft .4s .5s ease-out forwards;
        ` : 
        css`
          animation: slideOutFromLeft -s ease-out forwards;
        `
      }

    opacity:0;
   
    display: ${props => (props.index !== props.currentSlide  ? 'none' : 'block')};
    position: absolute;
    background-color:black;
    border:0;
    top:80px;
    left: 130px;
    border: 2px solid rgb(255, 255, 255, .25);
    border-radius: 12px;
    width: 1000px;
    height: 560px;
    box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;
  }

  
  @media (max-width:800px)
  {
    

    iframe
    {
      width:300px;
      height:180px;
    }
  }
`;

const Container = styled.main`

    position: fixed;
    opacity: 1;
    z-index: -1;
    background-size: cover;
    width:100vw;
    height:100vh;
    background-color:dark-gray;

`;

export default Home;